<template>
  <div id="dashboard">
    <div v-if="evento.length != 0" class="card card-body bg-light mt-2 mb-2">
      <div class="row">
        <div class="col-lg-3">
          <small>Nombre del evento</small>
          <h5 class="font-weight-bold text-primary">{{ evento.evento }}</h5>
        </div>
        <div class="col-lg-2">
          <small>Fecha de inicio</small>
          <h5 class="font-weight-bold text-primary">
            {{ evento.fecha_desde }}
          </h5>
        </div>
        <div class="col-lg-2">
          <small>Fecha de cierre</small>
          <h5 class="font-weight-bold text-primary">
            {{ evento.fecha_hasta }}
          </h5>
        </div>
        <div class="col-lg-2">
          <small>Aforo</small>
          <h5 class="font-weight-bold text-primary">
            {{ evento.aforo }}
          </h5>
        </div>
        <div class="col-lg-2">
          <small>Vendido</small>
          <h5 class="font-weight-bold text-primary">
            {{ evento.vendido }}
          </h5>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 text-right">
          <button
            v-on:click="get_tipos_lugares()"
            data-toggle="modal"
            data-target="#editar"
            class="btn btn-primary btn-sm btn-rounded"
          >
            <i class="fa fa-edit"></i> Editar evento
          </button>
        </div>
      </div>
    </div>
    <div v-if="ingresos.length != 0">
      <nav>
        <div class="nav nav-tabs mt-3" id="nav-tab" role="tablist">
          <a
            class="nav-link active"
            id="nav-home-tab"
            data-toggle="tab"
            href="#nav-home"
            role="tab"
            aria-controls="nav-home"
            aria-selected="true"
          >
            Vista gráfica
          </a>
          <a
            class="nav-link"
            id="nav-profile-tab"
            data-toggle="tab"
            href="#nav-profile"
            role="tab"
            aria-controls="nav-profile"
            aria-selected="false"
          >
            Vista tabla
          </a>
        </div>
      </nav>
      <div class="tab-content" id="nav-tabContent">
        <div
          class="tab-pane fade show active"
          id="nav-home"
          role="tabpanel"
          aria-labelledby="nav-home-tab"
        >
          <div
            v-for="(localidad, index_localidad) in ingresos.localidades"
            v-bind:key="index_localidad"
            id="accordion"
          >
            <div class="card border mt-2">
              <div class="card-header" v-bind:id="'heading_' + index_localidad">
                <h5 class="mb-0">
                  <button
                    class="btn btn-link text-primary p-0 collapsed"
                    data-toggle="collapse"
                    v-bind:data-target="'#collapse_' + index_localidad"
                    aria-expanded="false"
                    v-bind:aria-controls="'collapse_' + index_localidad"
                  >
                    <strong
                      >{{ localidad.localidad }} -
                      {{ localidad.porc_ingresos }}%
                    </strong>
                  </button>
                  <div class="progress mt-1">
                    <div
                      class="progress-bar progress-bar-striped progress-bar-animated"
                      v-bind:class="{
                        'bg-warning':
                          localidad.porc_ingresos > 0 &&
                          localidad.porc_ingresos < 50,
                        'bg-info':
                          localidad.porc_ingresos > 50 &&
                          localidad.porc_ingresos < 100,
                        'bg-success': localidad.porc_ingresos == 100,
                      }"
                      role="progressbar"
                      v-bind:aria-valuenow="localidad.porc_ingresos"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      v-bind:style="'width:' + localidad.porc_ingresos + '%'"
                    ></div>
                  </div>
                </h5>
              </div>
              <div
                v-bind:id="'collapse_' + index_localidad"
                class="collapse"
                v-bind:aria-labelledby="'heading_' + index_localidad"
                data-parent="#accordion"
              >
                <div class="card-body">
                  <ul>
                    <li>Cantidad de entradas: {{ localidad.num_entradas }}</li>
                    <li>Cantidad de ingresos: {{ localidad.num_ingresos }}</li>
                    <li>Cantidad vendida: {{ localidad.vendidas }}</li>
                    <li>Porcentaje de aforo: {{ localidad.porc_ingresos }}%</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="nav-profile"
          role="tabpanel"
          aria-labelledby="nav-profile-tab"
        >
          <div class="table-responsive">
            <table
              class="table table-bordered table-condensed table-hover my_table"
            >
              <thead>
                <tr>
                  <th>Localidad</th>
                  <th>Códigos registrados</th>
                  <th>Códigos vendidos</th>
                  <th>Registros de ingreso</th>
                  <th class="text-center">% aforo</th>
                  <th v-if="perfil == 1" class="text-center">Opciones</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(localidad, index_localidad) in ingresos.localidades"
                  v-bind:key="index_localidad"
                >
                  <td>{{ localidad.localidad }}</td>
                  <td>{{ localidad.num_entradas }}</td>
                  <td>{{ localidad.vendidas }}</td>
                  <td>{{ localidad.num_ingresos }}</td>
                  <td class="text-center">
                    <div class="progress mt-1">
                      <div
                        class="progress-bar progress-bar-striped progress-bar-animated"
                        v-bind:class="{
                          'bg-warning':
                            localidad.porc_ingresos > 0 &&
                            localidad.porc_ingresos < 50,
                          'bg-info':
                            localidad.porc_ingresos > 50 &&
                            localidad.porc_ingresos < 100,
                          'bg-success': localidad.porc_ingresos == 100,
                        }"
                        role="progressbar"
                        v-bind:aria-valuenow="localidad.porc_ingresos"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        v-bind:style="'width:' + localidad.porc_ingresos + '%'"
                      ></div>
                    </div>
                    {{ localidad.porc_ingresos }}%
                  </td>
                  <td v-if="perfil == 1" class="text-center">
                    <button
                      v-on:click="index_selected = index_localidad"
                      data-toggle="modal"
                      data-target="#limpiar"
                      class="btn btn-danger btn-sm"
                    >
                      <i class="fas fa-broom"></i> Limpiar datos
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="limpiar">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header bg-danger">
            <h5 class="modal-title text-white">Limpiar datos</h5>
            <button type="button" class="close" data-dismiss="modal">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="alert alert-danger">
              <strong>
                <i class="fa fa-exclamation-circle"></i> ¡Precaución! </strong
              ><br />
              Esta opción eliminará todos los datos de registros de ingresos de
              esta localidad. Esta operación no se puede deshacer. <br />
              ¿Desear limpiar los datos?
            </div>
            <div class="text-right">
              <button v-on:click="limpiar_datos" class="btn btn-danger">
                Sí, limpiar
              </button>
              <button data-dismiss="modal" class="btn btn-outline-dark ml-1">
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="editar">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form v-on:submit.prevent="update_evento">
            <div class="modal-header">
              <h5 class="modal-title text-primary">Editar evento</h5>
              <button type="button" class="close" data-dismiss="modal">
                <span>&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div v-if="evento.length != 0">
                <transition appear name="slide-fade">
                  <div
                    v-if="msg_edit.type != null"
                    class="alert"
                    v-bind:class="{
                      'alert-success': msg_edit.type,
                      'alert-danger': !msg_edit.type,
                    }"
                  >
                    <i v-if="msg_edit.type" class="fa fa-check-circle"></i>
                    <i
                      v-if="!msg_edit.type"
                      class="fa fa-exclamation-circle"
                    ></i>
                    {{ msg_edit.text }}
                  </div>
                </transition>
                <div class="form-group">
                  <label>Nombre del evento</label>
                  <input
                    v-model="evento.evento"
                    type="text"
                    class="form-control my-form-control"
                    required
                  />
                </div>

                <div class="form-group">
                  <label>Tipo de lugar</label>
                  <select v-model="evento.id_tipo_lugar" class="form-control">
                    <option value="0">Por defecto</option>
                    <option
                      v-for="(tipo_lugar, index_tipo_lugar) in tipos_lugares"
                      v-bind:key="index_tipo_lugar"
                      v-bind:value="tipo_lugar.id_tipo_lugar"
                    >
                      {{ tipo_lugar.tipo_lugar }}
                    </option>
                  </select>
                </div>

                <div class="form-group">
                  <label>Fecha de inicio</label>
                  <input
                    v-model="evento.fecha_desde"
                    type="date"
                    class="form-control my-form-control"
                    required
                  />
                </div>

                <div class="form-group">
                  <label>Hora de inicio</label>
                  <input
                    v-model="evento.hora_desde"
                    type="time"
                    class="form-control my-form-control"
                    required
                  />
                </div>

                <div class="form-group">
                  <label>Fecha de cierre</label>
                  <input
                    v-model="evento.fecha_hasta"
                    type="date"
                    class="form-control my-form-control"
                    required
                  />
                </div>

                <div class="form-group">
                  <label>Hora de cierre</label>
                  <input
                    v-model="evento.hora_hasta"
                    type="time"
                    class="form-control my-form-control"
                    required
                  />
                </div>

                <div class="form-group">
                  <label>Aforo del evento</label>
                  <input
                    v-model="evento.aforo"
                    type="text"
                    class="form-control my-form-control"
                    required
                  />
                </div>

                <div class="form-group">
                  <label>Ventas del evento</label>
                  <input
                    v-model="evento.vendido"
                    type="text"
                    class="form-control my-form-control"
                    required
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-outline-dark btn-rounded btn-sm light"
                data-dismiss="modal"
              >
                Cancelar
              </button>
              <button type="submit" class="btn btn-success btn-sm btn-rounded">
                <i class="fa fa-save"></i> Guardar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "Dashboard",
  data() {
    return {
      loading: true,
      id_evento: null,
      ingresos: [],
      index_selected: null,
      msg_edit: {
        type: null,
        text: null,
      },
      tipos_lugares: [],
    };
  },
  props: {
    evento: Object,
    perfil: String,
  },
  created: function () {
    this.loading = false;
    this.get_ingresos();
  },
  methods: {
    get_ingresos: function () {
      this.id_evento = this.$route.params.id_evento;
      var session = localStorage.getItem("sess_taqui_web");
      session = JSON.parse(session);
      var token_session = session.token;
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.$api_key,
        token: token_session,
      };
      let config = {
        headers: headers,
      };
      axios
        .get(
          this.$base_url + "eventos/ingresos_localidades/" + this.id_evento,
          config,
          {
            headers: headers,
          }
        )
        .then((response) => {
          if (response.data.status) {
            this.ingresos = response.data.data;
            this.total_ingresos = response.data.data.total_ingresos;
          }
        })
        .catch()
        .finally();
    },
    limpiar_datos: function () {
      var id_localidad = this.ingresos.localidades[this.index_selected]
        .id_localidad;
      var session = localStorage.getItem("sess_taqui_web");
      session = JSON.parse(session);
      var token_session = session.token;
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.$api_key,
        token: token_session,
      };
      var params = {
        id_localidad: id_localidad,
      };
      const vm = this;
      axios
        .post(this.$base_url + "eventos/limpiar_registros", params, {
          headers: headers,
        })
        .then(function (response) {
          if (response.data.status) {
            vm.$notify({
              group: "foo",
              type: "success",
              title: "Eventos",
              text: "Evento registrado.",
            });
            location.reload();
          } else {
            vm.msg_nuevo = {
              type: false,
              text: response.data.message,
            };
            vm.$notify({
              group: "foo",
              type: "error",
              title: "Eventos",
              text: response.data.message,
            });
            console.log(response);
          }
        })
        .catch((error) => {
          vm.msg_nuevo = {
            type: false,
            text: error,
          };
          vm.$notify({
            group: "foo",
            type: "error",
            title: "Eventos",
            text: error,
          });
        });
    },
    get_tipos_lugares: function () {
      var session = localStorage.getItem("sess_taqui_web");
      session = JSON.parse(session);
      var token_session = session.token;
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.$api_key,
        token: token_session,
      };
      let config = {
        headers: headers,
      };
      axios
        .get(this.$base_url + "eventos/tipos_lugares", config, {
          headers: headers,
        })
        .then((response) => {
          if (response.data.status) {
            this.tipos_lugares = response.data.data;
          }
        })
        .catch()
        .finally();
    },
    update_evento: function () {
      this.msg_edit = {
        type: null,
        msg: null,
      };
      var session = localStorage.getItem("sess_taqui_web");
      session = JSON.parse(session);
      var token_session = session.token;
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.$api_key,
        Token: token_session,
      };
      var params = {
        id_evento: this.evento.id_evento,
        evento: this.evento.evento,
        id_tipo_lugar: this.evento.id_tipo_lugar,
        fecha_desde: this.evento.fecha_desde,
        fecha_hasta: this.evento.fecha_hasta,
        hora_desde: this.evento.hora_desde,
        hora_hasta: this.evento.hora_hasta,
        aforo: this.evento.aforo,
        vendido: this.evento.vendido,
      };
      const vm = this;
      axios
        .put(this.$base_url + "eventos/update_evento", params, {
          headers: headers,
        })
        .then(function (response) {
          if (response.data.status) {
            vm.msg_edit = {
              type: true,
              text: "Evento actualizado.",
            };
            location.reload();
          } else {
            vm.msg_edit = {
              type: false,
              text: response.data.message,
            };
            console.log(response);
          }
        })
        .catch((error) => {
          vm.msg_edit = {
            type: false,
            text: error,
          };
        });
    },
  },
  mounted: function () {
    setInterval(() => {
      this.get_ingresos();
    }, 60000);
  },
};
</script>